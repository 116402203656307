import api from "./api";

const ENDPOINT_BASE = '/employee';

/** Traz os colaboradores cadastrados, filtrando por parametros (qryStr) */
function getAll(/* { page, pageSize, orderBy, orderDir, search, field } */) {
    return api.get(`${ENDPOINT_BASE}`, /* {
        params: {
            page,
            pageSize,
            orderBy,
            orderDir,
            ...((search & field) !== undefined) && {search, field},
        }
    } */);
}

/** Traz da API o colaborador da matricula recebida na function */
function getByMat(mat) {
    return api.get(`${ENDPOINT_BASE}/${mat}`);
}

/** salva um novo colaborador */
function createNew(form) {
    return api.post(`${ENDPOINT_BASE}/create`, { ...form });
}

/** atualiza dados de um colaborador */
function updateOne(mat, form) {
    return api.put(`${ENDPOINT_BASE}/update/${mat}`, { ...form });
}

function deleteByMat(payload) {
    return api.patch(
        `${ENDPOINT_BASE}/change-active`,
        {...payload}
    );
}

/** Traz o histórico de dados de um colaborador */
function getEmployeeHistory(id){
    return api.get(`/colaborador/${id}`)
}

const ColaboradorApiService = {
    getAll,
    getByMat,
    createNew,
    updateOne,
    deleteByMat,
    getEmployeeHistory
}

export default ColaboradorApiService;
