import React, { useEffect, useState } from 'react';
import {Table} from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import { Button } from 'react-bootstrap';
import PageContainer from '../../../components/PageContainer';
import * as R from "ramda";
import UtilService from '../../../services/util';
import RelatorioApiService from '../../../services/api/RelatorioApiService';
import CustomModal from '../../../components/CustomModal';
import { useHistory } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Rectangle } from 'recharts';
import Loading from '../../../components/Loading';
import { FiInfo } from 'react-icons/fi';

function RelatorioComparativoView() {
    //uses
    const params = useParams();
    const history = useHistory();

    //States
    const [custosPrevistos, setCustosPrevistos] = useState([]);
    const [custoForMonth, setCustoForMonth] = useState([]);
    const [custoPrevistoTotal, setCustoPrevistoTotal] = useState();
    const [custoRealTotal, setCustoRealTotal] = useState();
    const [visiblePopup, setVisiblePopup] = useState(null);
    const [loading, setLoading] = useState(false);

    //styles
    const popupStyle = {
        position: 'absolute',
        top: '-250%',
        left: '-70%',
        transform: 'translateX(-55%)',
        backgroundColor: '#e9e9e9',
        color: '#212529',
        border: '1px solid #dee2e6',
        borderRadius: '5px',
        padding: '5px 10px',
        zIndex: 1000,
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        width: 'auto',
        fontWeight: 'normal'
    };

    //Funções
    const getCustoReal = async (projetoSelecionado, anoSelecionado) => {
        setLoading(true);
        const fetchData = async () => {
            const obejtoMeses = [
                { label: 'Jan', numeric: '01' },
                { label: 'Feb', numeric: '02' },
                { label: 'Mar', numeric: '03' },
                { label: 'Apr', numeric: '04' },
                { label: 'May', numeric: '05' },
                { label: 'Jun', numeric: '06' },
                { label: 'Jul', numeric: '07' },
                { label: 'Aug', numeric: '08' },
                { label: 'Sep', numeric: '09' },
                { label: 'Oct', numeric: '10' },
                { label: 'Nov', numeric: '11' },
                { label: 'Dec', numeric: '12' },
            ];

            try {
                const promises = obejtoMeses.map(async (mes) => {
                    const response = await ProjetosApiService.getCustosProjeto(projetoSelecionado, "01-" + mes.label + "-" + anoSelecionado, "01-" + mes.label + "-" + anoSelecionado);
                    const lastDay = new Date(anoSelecionado, mes.numeric, 0).getDate();
                    const respHH = await ProjetosApiService.getDetailProject(projetoSelecionado, "01/"+mes.numeric+"/"+anoSelecionado, lastDay+"/"+mes.numeric+"/"+anoSelecionado)
                    
                    return { month: mes.label, data: response.data, dataHH: respHH.data.total };
                });

                const responseData = await Promise.all(promises);
                setCustoForMonth(responseData);
                calcularCustoRealTotal(responseData);
                
            } catch (error) {
                console.error('Erro ao buscar dados da API:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }

    const getCustosPrevistos = async (projetoSelecionado, anoSelecionado) => {
        setLoading(true);

        const fetchData = async () => {
            const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

            try {
                const promises = months.map(async (month) => {
                const response = await RelatorioApiService.getPredictedCost(projetoSelecionado, `${month}-${anoSelecionado}`);
                return { month, data: response.data };
                });
                
                const responseData = await Promise.all(promises);
                setCustosPrevistos(responseData);
                calcularCustoPrevistoTotal(responseData);

            } catch (error) {
                setLoading(false);
                console.error('Erro ao buscar dados da API:', error);
            }finally{
                setLoading(false);
            }
        };
        fetchData();
    }

    const calcularCustoPrevistoTotal = (dados) => {
        return dados.reduce((total, mes) => {
            const { previstoFuncao, previstoIndireto } = mes.data;
    
            if (!previstoFuncao || !Array.isArray(previstoFuncao)) {
                console.error("Erro: previstoFuncao não está definido ou não é um array.");
                return total;
            }
    
            if (!previstoIndireto || !Array.isArray(previstoIndireto)) {
                console.error("Erro: previstoIndireto não está definido ou não é um array.");
                return total;
            }
    
            const totalFuncao = previstoFuncao.reduce((acc, funcao) => {
                const custoTotal = parseFloat(funcao.custo_total_funcao);
                if (isNaN(custoTotal)) {
                    console.error("Erro: custo_total_funcao não é um número válido.", funcao);
                    return acc;
                }
                return acc + custoTotal;
            }, 0);
    
            const totalIndireto = previstoIndireto.reduce((acc, indireto) => {
                const valorCusto = parseFloat(indireto.valor_custo_indireto);
                if (isNaN(valorCusto)) {
                    console.error("Erro: valor_custo_indireto não é um número válido.", indireto);
                    return acc;
                }
                return acc + valorCusto;
            }, 0);

            const custoTotalFloat = total + totalFuncao + totalIndireto;
            setCustoPrevistoTotal(parseFloat(custoTotalFloat).toLocaleString('pt-br'))
            return total + totalFuncao + totalIndireto;
        }, 0);

    };

    const calcularCustoRealTotal = (dados) => {
        let total = 0;
        dados.forEach((item) => {
        total += item.dataHH;
            item.data.forEach((mes) => {
                if(mes.cotacao == 0) {
                    total += parseFloat(mes.valor)
                } else {
                    total += parseFloat(mes.cotacao) * parseFloat(mes.valor);
                }
            });
        });

        setCustoRealTotal(total.toLocaleString('pt-br'));
        return total;
    };

    const handleVoltar = () => {
        history.goBack();
    }

    const actions = [
        {
            label: 'Voltar',
            icon: <MdOutlineArrowBack />,
            color: 'info',
            type: 'button',
            onClick: handleVoltar
        }
    ]

    const convertValueInReal = (custoValue, custoMoeda) => {
        let convertedValue = custoValue;
        if (custoMoeda > 0 || custoMoeda < 0) {
            convertedValue = R.multiply(custoValue, custoMoeda);
        }

        return convertedValue*1;
    }

    useEffect(()=> {
        getCustoReal(params.key, params.ano);
        getCustosPrevistos(params.key, params.ano);
    }, []);

    const sumCost = (custoForMonth, month) => {
        const filteredItems = custoForMonth.filter(item => item.month.toLowerCase() === month.toLowerCase());
        if (filteredItems.length === 0) return "-";
        let sum = 0

        filteredItems[0].data.map(custo => {
            const cost = convertValueInReal(custo.valor, custo.cotacao);
            sum= sum+cost
        })
        sum = sum + filteredItems[0].dataHH;
        if(sum === 0) return sum = '-'

        return sum.toLocaleString('pt-br')
    }


    const sumCostByMonth = (previstoFuncao, previstoIndireto) => {
        const previstoFuncaoTotal = previstoFuncao.reduce((total, item) => {
            return total + parseFloat(item.custo_total_funcao);
        }, 0);

        const previstoIndiretoTotal = previstoIndireto.reduce((total, item) => {
            return total + parseFloat(item.valor_custo_indireto);
        }, 0);

        return parseFloat(previstoFuncaoTotal+previstoIndiretoTotal).toLocaleString('pt-br')
    }

    const getCostByMonth = (month) => {
        const response = ["-"]
        custosPrevistos.map(costForMonth => {
            if(costForMonth.month == month) { 
                if(costForMonth.data.previstoFuncao.length > 0 || costForMonth.data.previstoIndireto.length > 0){
                    return response.push(sumCostByMonth(costForMonth.data.previstoFuncao, costForMonth.data.previstoIndireto))
                } else {
                    return response.push("-")
                }
            }
        })
        const responseFormat = response.pop()
        return responseFormat
    };

    const calcVariation = (predictedCost, realCost) => {
        if(predictedCost != undefined && realCost != undefined) {
            if(realCost.includes("-") && !predictedCost.includes("-")) {

                return "-100%"
            }

            if(predictedCost.includes("-") && !realCost.includes("-")) {

                return "100%"
            }

            if(predictedCost == "0") {
                return "100%"
            }

            if(realCost == "0") {
                return "-100%"
            }

            if(!realCost.includes("-") && !predictedCost.includes("-")) {
                const realCostFormat = realCost.replace(/[.]/g, '');
                const realCostFormatFinal = realCostFormat.replace(/[,]/g, '.')*1;
                const predictedCostFormat = predictedCost.replace(/[.]/g, '');
                const predictedCostFormatFinal = predictedCostFormat.replace(/[,]/g, '.')*1;

                const diferenca = R.subtract(predictedCostFormatFinal, realCostFormatFinal)
                const variacao = R.divide(diferenca, predictedCostFormatFinal)

                return parseFloat(variacao).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })
            }
        }
        return "-"
    }

    const calcVariationGrafic = (predictedCost, realCost) => {
        if(predictedCost != undefined && realCost != undefined) {
            const realCostFormat = realCost.replace(/[.]/g, '');
            const realCostFormatFinal = realCostFormat.replace(/[,]/g, '.')*1;
            const predictedCostFormat = predictedCost.replace(/[.]/g, '');
            const predictedCostFormatFinal = predictedCostFormat.replace(/[,]/g, '.')*1;

            const diferenca = R.subtract(predictedCostFormatFinal, realCostFormatFinal)
            
            return diferenca
        }
        return "-"

    }

    const months = [
        { month: 'Janeiro', realCost: `R$ ${sumCost(custoForMonth, 'Jan')}`, predictedCost: `R$ ${getCostByMonth("01")}`, variation:  calcVariation( getCostByMonth("01"), sumCost(custoForMonth, 'Jan') ) },
        { month: 'Fevereiro', realCost: `R$ ${sumCost(custoForMonth, 'Feb')}`, predictedCost: `R$ ${getCostByMonth("02")}`, variation: calcVariation( getCostByMonth("02"), sumCost(custoForMonth, 'Feb') ) },
        { month: 'Março', realCost: `R$ ${sumCost(custoForMonth, 'Mar')}`, predictedCost: `R$ ${getCostByMonth("03")}`, variation: calcVariation( getCostByMonth("03"), sumCost(custoForMonth, 'Mar') )  },
        { month: 'Abril', realCost: `R$ ${sumCost(custoForMonth, 'Apr')}`, predictedCost: `R$ ${getCostByMonth("04")}`, variation: calcVariation( getCostByMonth("04"), sumCost(custoForMonth, 'Apr') )  },
        { month: 'Maio', realCost: `R$ ${sumCost(custoForMonth, 'May')}`, predictedCost: `R$ ${getCostByMonth("05")}`, variation: calcVariation( getCostByMonth("05"), sumCost(custoForMonth, 'May') )  },
        { month: 'Junho', realCost: `R$ ${sumCost(custoForMonth, 'Jun')}`, predictedCost: `R$ ${getCostByMonth("06")}`, variation: calcVariation( getCostByMonth("06"), sumCost(custoForMonth, 'Jun') )  },
        { month: 'Julho', realCost: `R$ ${sumCost(custoForMonth, 'Jul')}`, predictedCost: `R$ ${getCostByMonth("07")}`, variation: calcVariation( getCostByMonth("07"), sumCost(custoForMonth, 'Jul') )  },
        { month: 'Agosto', realCost: `R$ ${sumCost(custoForMonth, 'Aug')}`, predictedCost: `R$ ${getCostByMonth("08")}`, variation: calcVariation( getCostByMonth("08"), sumCost(custoForMonth, 'Aug') )  },
        { month: 'Setembro', realCost: `R$ ${sumCost(custoForMonth, 'Sep')}`, predictedCost: `R$ ${getCostByMonth("09")}`, variation: calcVariation( getCostByMonth("09"), sumCost(custoForMonth, 'Sep') )  },
        { month: 'Outubro', realCost: `R$ ${sumCost(custoForMonth, 'Oct')}`, predictedCost: `R$ ${getCostByMonth("10")}`, variation: calcVariation( getCostByMonth("10"), sumCost(custoForMonth, 'Oct') )  },
        { month: 'Novembro', realCost: `R$ ${sumCost(custoForMonth, 'Nov')}`, predictedCost: `R$ ${getCostByMonth("11")}`, variation: calcVariation( getCostByMonth("11"), sumCost(custoForMonth, 'Nov') )  },
        { month: 'Dezembro', realCost: `R$ ${sumCost(custoForMonth, 'Dec')}`, predictedCost: `R$ ${getCostByMonth("12")}`, variation: calcVariation( getCostByMonth("12"), sumCost(custoForMonth, 'Dec') )  },
        {month: "", realCost: `R$ ${custoRealTotal}`, predictedCost : `R$ ${custoPrevistoTotal}`, variation: calcVariation(custoPrevistoTotal, custoRealTotal)}

    ];

    const monthsGrafic = [
        { month: 'Janeiro', realCost: parseFloat(sumCost(custoForMonth, 'Jan').replace('R$ ', '').replace('.', '').replace(',', '.')), predictedCost: parseFloat(getCostByMonth('01').replace('R$ ', '').replace('.', '').replace(',', '.')),  variation:  calcVariationGrafic( getCostByMonth("01"), sumCost(custoForMonth, 'Jan') ) } ,
        { month: 'Fevereiro', realCost: parseFloat(sumCost(custoForMonth, 'Feb').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost: parseFloat(getCostByMonth('02').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("02"), sumCost(custoForMonth, 'Feb') )  },
        { month: 'Março', realCost: parseFloat(sumCost(custoForMonth, 'Mar').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('03').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("03"), sumCost(custoForMonth, 'Mar') )   },
        { month: 'Abril', realCost: parseFloat(sumCost(custoForMonth, 'Apr').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('04').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("04"), sumCost(custoForMonth, 'Apr') )  },
        { month: 'Maio', realCost: parseFloat(sumCost(custoForMonth, 'May').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('05').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("05"), sumCost(custoForMonth, 'May') )  },
        { month: 'Junho', realCost: parseFloat(sumCost(custoForMonth, 'Jun').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('06').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("06"), sumCost(custoForMonth, 'Jun') )  },
        { month: 'Julho', realCost: parseFloat(sumCost(custoForMonth, 'Jul').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('07').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("07"), sumCost(custoForMonth, 'Jul') )  },
        { month: 'Agosto', realCost: parseFloat(sumCost(custoForMonth, 'Aug').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('08').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("08"), sumCost(custoForMonth, 'Aug') )  },
        { month: 'Setembro', realCost: parseFloat(sumCost(custoForMonth, 'Sep').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('09').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("09"), sumCost(custoForMonth, 'Sep') )  },
        { month: 'Outubro', realCost: parseFloat(sumCost(custoForMonth, 'Oct').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('10').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("10"), sumCost(custoForMonth, 'Oct') )  },
        { month: 'Novembro', realCost: parseFloat(sumCost(custoForMonth, 'Nov').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('11').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("11"), sumCost(custoForMonth, 'Nov') )  },
        { month: 'Dezembro', realCost: parseFloat(sumCost(custoForMonth, 'Dec').replace('R$ ', '').replace('.', '').replace(',', '.')),  predictedCost:  parseFloat(getCostByMonth('12').replace('R$ ', '').replace('.', '').replace(',', '.')), variation: calcVariationGrafic( getCostByMonth("12"), sumCost(custoForMonth, 'Dec') )  },
    ];

    const exportDataInExcel = async () => {
        const header = [
            [
                'Mês', 'Custo Previsto', 'Custo Real', 'Variação',
            ]
        ];

        const formatedData = await months.map(item => {
            if (item['month'] == "") {
                item['month'] = "Total";
            }
    
            const formatCost = (cost) => {
                if (cost === "-") return ""; // Substitui "-" por vazio
                return parseFloat(cost.replace('R$ ', '').replace('.', '').replace(',', '.')) || "";
            };
    
            return {
                mes: item['month'],
                predictedCost: formatCost(item['predictedCost']),
                realCost: formatCost(item['realCost']),
                variation: item["variation"],
            };
        });
    
        UtilService.exportXls(formatedData, `Relatório: ${params.key} - ${params.ano}`, header);
    };

    const getIntroOfPage = (variation, predictedCost) => {
        if (!isNaN(variation) && !isNaN(predictedCost)) {
            const percent = R.divide(variation, predictedCost)
            
            return parseFloat(percent).toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 });
        }

        return '-';
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (        
                <div
                    tabIndex="-1"
                    className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom"
                    >
                    <div
                        className="recharts-default-tooltip"
                        style={{
                        margin: '0px',
                        padding: '10px',
                        backgroundColor: 'rgb(255, 255, 255)',
                        border: '1px solid rgb(204, 204, 204)',
                        whiteSpace: 'nowrap'
                        }}
                    >
                        <p className="recharts-tooltip-label" style={{ margin: '0px' }}>
                        {label}
                        </p>
                        <ul className="recharts-tooltip-item-list" style={{ padding: '0px', margin: '0px' }}>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: '#00c49f' }}>
                            <span className="recharts-tooltip-item-name">Custo Previsto</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">R$ {!isNaN(payload[0].value) ? payload[0].value.toLocaleString('pt-br') : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: '#0088fe' }}>
                            <span className="recharts-tooltip-item-name">Custo Real</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">R$ {!isNaN(payload[1].value) ? payload[1].value.toLocaleString('pt-br') : "-" }</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        <li className="recharts-tooltip-item" style={{ display: 'block', paddingTop: '4px', paddingBottom: '4px', color: 'rgb(255, 115, 0)' }}>
                            <span className="recharts-tooltip-item-name">Variação</span>
                            <span className="recharts-tooltip-item-separator"> : </span>
                            <span className="recharts-tooltip-item-value">{getIntroOfPage(payload[2].value, payload[0].value)}</span>
                            <span className="recharts-tooltip-item-unit"></span>
                        </li>
                        </ul>
                    </div>
                </div>
            );
        }

        return null;
    };

    const processCosts = (data) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        let sumPredictedCost = 0;

        data.forEach((item) => {
            const itemDate = new Date(`${item.month.split('-')[1]}-${item.month.split('-')[0]}-01`);
            const itemYear = itemDate.getFullYear();
            const itemMonth = itemDate.getMonth();

            const predictedCost = parseFloat(item.predictedCost.replace('R$', '').replace(',', '')) || 0;

            if (itemYear < currentYear || (itemYear === currentYear && itemMonth < currentMonth)) {
                sumPredictedCost += predictedCost;
            }
        });

        return {
            sumPredictedCost: sumPredictedCost.toFixed(2)
        };
    };

    const processCostsReal = (data) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        
        let sumRealCost = 0;

        data.forEach((item) => {

            const itemDate = new Date(`${item.month.split('-')[1]}-${item.month.split('-')[0]}-01`);
            const itemYear = itemDate.getFullYear();
            const itemMonth = itemDate.getMonth();

            const realCost = parseFloat(item.realCost.replace('R$', '').replace(',', '')) || 0;

            if (itemYear < currentYear || (itemYear === currentYear && itemMonth < currentMonth)) {
                sumRealCost += realCost;
            }
        });

        return {
            sumRealCost: sumRealCost.toFixed(2)
        };
    };

    const total_predict_acumulated = processCosts(months);
    const total_real_acumulated = processCostsReal(months)
    const diferenca = R.subtract(Number(total_predict_acumulated.sumPredictedCost), Number(total_real_acumulated.sumRealCost));
    var total_variaiton_acumulated = diferenca/Number(total_predict_acumulated.sumPredictedCost) || "-";
    total_variaiton_acumulated = total_variaiton_acumulated === -Infinity ? "-" : total_variaiton_acumulated;

    const handleMouseEnter = (popupId) => {
        setVisiblePopup(popupId);
    };

    const handleMouseLeave = () => {
        setVisiblePopup(null);
    };

    return(    
    <PageContainer title={`Comparativo de Custo ${params.key} - ${params.ano}`} actions={actions} >
        {loading ? <Loading message="Carregando dados..." /> :
        custoPrevistoTotal == 0 && custoRealTotal == 0 ? <h5>Não existe dados para o período selecionado.</h5> :  
        <>
            <Button onClick={exportDataInExcel} className='mb-3'>Exportar</Button>
            <div className='w-25'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>
                                Total Variação Acumulada 
                                <span
                                    onMouseEnter={() => handleMouseEnter('variation_total')}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-1 info-icon"
                                    style={{ position: 'relative', cursor: 'pointer' }}
                                >
                                    <FiInfo />
                                    {visiblePopup === 'variation_total' && (
                                        <div className="hover-popup" style={popupStyle}>
                                            Diferença entre o Custo Previsto e o Custo Real acumulado até o último mês fechado.
                                        </div>
                                    )}
                                </span>
                            </th>
                            {total_variaiton_acumulated < 0 ? 
                                <th style={{color: "red"}}>{total_variaiton_acumulated.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</th>
                            : 
                            
                                <th style={{color: "green"}}>{total_variaiton_acumulated.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</th>
                            }
                        </tr>
                    </thead>
                </Table>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Mês</th>
                    <th>Custo Previsto</th>
                    <th>Custo Real</th>
                    <th>
                        Variação
                        <span
                            onMouseEnter={() => handleMouseEnter('variation')}
                            onMouseLeave={handleMouseLeave}
                            className="ml-1 info-icon"
                            style={{ position: 'relative', cursor: 'pointer' }}
                        >
                            <FiInfo />
                            {visiblePopup === 'variation' && (
                                <div className="hover-popup" style={popupStyle}>
                                    Diferença entre o Custo Previsto e o Custo Real: (CP - CR) / CP
                                </div>
                            )}
                        </span>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {months.map((month, index) => {
                        const variationNumber = month.variation.replace("%", "").replace(",", ".")*1;

                        let color = '';
                        let size = ''

                        if (variationNumber >= 5) {
                            color = 'green';
                            size = 'bold';
                        } else if (variationNumber <= -5) {
                            color = 'red';
                            size = 'bold'
                        } else {
                            color = 'black';
                            size = 'normal'
                        }

                        return (
                            <tr key={index}>
                                <td>{index + 1 == 13 ? <b>Total</b> : index + 1 }</td>
                                <td>{month.month}</td>
                                <td>{month.predictedCost}</td>
                                <td>{month.realCost}</td>
                                <td style={{color: color, fontWeight: size}}>{month.variation}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={monthsGrafic}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={(value) => `R$ ${value.toLocaleString('pt-br')}`} />
                    <Tooltip content={<CustomTooltip />} /> 
                    <Legend />
                    <Bar dataKey="predictedCost" fill="#00c49f" name="Custo Previsto" />
                    <Bar dataKey="realCost" fill="#0088fe" cursor="pointer" name="Custo Real" />
                    <Line strokeWidth={2} type="monotone" dataKey="variation" name="Variação" stroke="#ff7300" />
                </ComposedChart>
            </ResponsiveContainer>
            <CustomModal/>
        </>}
    </PageContainer>
    )
} export default RelatorioComparativoView