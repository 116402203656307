import * as Yup from 'yup';
import UtilService from '../util';
import ValidationService from './validation';

const schema = Yup.object().shape({
    nome: Yup
        .string()
        .required("Campo obrigatório."),

    sobrenome: Yup
        .string()
        .required("Campo obrigatório."),

    cpf: Yup
        .string()
        .required("Campo obrigatório")
        .test({
            name: 'cpf-numbers',
            message: 'Deve conter apenas números',
            exclusive: false,
            test: (value) => {
                const isNull = [null, undefined].includes(value);
                if (isNull) return false;
                const regExp = /^[0-9]*$/g;
                const match = regExp.test(value);
                return match;
            }   
        })
        .length(11, "CPF deve possuir 11 dígitos."),
        
    /* nascimento: Yup
        .date() // "Data Nasc. preenchida é inválida."
        .nullable()
        .transform(UtilService.yupTransform)
        .max((new Date()), 'Não pode ser maior que hoje.')
        .required("Campo obrigatório"), */
                
    dependentes: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, )
        .default(0),
    
    endereco: Yup
        .string(),
    
    cidade: Yup
        .string(),
    
    email: Yup
        .string()
        .required("Campo obrigatório.")
        .email("E-mail preenchido é inválido."),
    
    filial: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),
    
    departamento: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),
               
    regime: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),
               
    funcao: Yup
        .number("Preencher com uma das opções.")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform),

    admissao: Yup
        .date() // "Admissão preenchido é inválida."
        .nullable()
        .transform(UtilService.yupTransform)
        .required("Campo obrigatório.")
        .max((new Date()), 'Não pode ser maior que hoje.'),
    
    demissao: Yup
        .date() // "Demissão preenchido é inválida."
        .nullable()
        .transform(UtilService.yupTransform)
        .max((new Date()), 'Não pode ser maior que hoje.'),
    
    salario: Yup
        .number("Preencher com valor numérico")
        .required("Campo obrigatório.")
        .nullable()
        .transform(UtilService.yupTransform)
        .min(0, 'Valor do salário deve ser informado.')
        .default(0),

    classificacao: Yup
        .string()
        .nullable()
        .transform(UtilService.yupTransform),

    carga_horaria: Yup
        .number("Preencher com valor numérico")
        .required("Campo obrigatório.")
        .nullable()
        .integer("Somente valores inteiros são permitidos.")
        .min(0, "O valor deve ser maior ou igual a 0.")
        .transform(UtilService.yupTransform),

    data_inicio: Yup 
        .date()
        .nullable()
        .transform(UtilService.yupTransform)
        .required("Campo obrigatório.")
        .max((new Date()), 'Não pode ser maior que hoje.'),
        
    observacao: Yup
        .string()
        .max(600, "Limite máximo de 600 caracteres."),
});

async function validate(form) {
    return ValidationService.validate(form, schema);
}

async function castValues(form) {
    return ValidationService.castValues(form, schema)
}

const ColaboradorValidation = {
    schema,
    validate,
    castValues
};

export default ColaboradorValidation;