import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Form, Dropdown, Button } from 'react-bootstrap';
import NothingToShow from '../NothingToShow';
import ListTopbar from './ListTopbar';
import ConvertDataService from '../../services/util/ConvertDatas';
import Select from 'react-select';
import './ListLayout.css';
import { useSelector } from 'react-redux';
import ProjetosApiService from '../../services/api/ProjetosApiService';
import ClienteoApiService from '../../services/api/CadastroClientesService';
import useAlertNotification from '../../context/AlertNotificationContext';
import { FaEraser, FaFilter, FaPlus } from 'react-icons/fa';
import ListTopbarCustom from './ListTopbarCustom';
import CustomTooltip from '../CustomTooltip';
import makeAnimated from 'react-select/animated';


/* function ListPagination(props) {
    const { first, last, prev, next, current } = props;
    return (
        <Pagination>
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item active>{2}</Pagination.Item>
            <Pagination.Item>{3}</Pagination.Item>
            <Pagination.Next />
        </Pagination>
    )
} */
const animatedComponents = makeAnimated();

function createOptionsFilterExtras(items, onSubmitFilter) {
    return items.map(item => {
        return <Form.Control
            as="select"
            className="select-item"
            onChange={(e) => {
                const { value } = e.target;
                if (value === '-1') {
                    onSubmitFilter({
                        column: null,
                        filter: null
                    })

                } else {
                    onSubmitFilter({
                        column: item.column,
                        filter: e.target.value,
                        isOptionFilter: true
                    })
                }

            }}>

            <option value='-1'>{item.tipo}</option>
            {item.data.map(option => <option key={`op-${option[item.label]}`} value={option[item.filter]}>{option[item.label]}</option>)}
        </Form.Control>
    })
}

function ListLayout({ getDataList, rowsDataCategorias, todosOsProjetos, todosLideres, todosClientes, isFilteredBool, setIsFilteredBool, setIsLoading, setVerificarRow, setRowsData, setFilteredData, columns, rows, rowsDataChecked, setRowsDataChecked, onRowClick, onSubmitFilter, actions, bordered, swipeColor, hover, columnsOptionsFilterExtras, firstColumn, optionInitialFilter, multiFilter, columnsNotFilter, cursorPointer }) {
    const [filterType, setFilterType] = useState({});
    const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
    const [anoSelecionado, setAnoSelecionado] = useState("");
    const [liderSelecionado, setLiderSelecionado] = useState("");
    const [clienteSelecionado, setClienteSelecionado] = useState("");
    const [nameClienteSelecionado, setNameClienteSelecionado] = useState("");
    const [noProjects, setNoProjects] = useState(false);
    const [filterSelecionado, setFilterSelecionado] = useState(false);
    const [listaAnos, setListaAnos] = useState([]);

    const alert = useAlertNotification();

    const stylePopup = {
        width: "max-content",
        top: "20px",
        transform: "translateX(-97%)"
    }

    useEffect(() => {
        setListaAnos(ConvertDataService.getAnosToMultiSelect())
    },[])

    useEffect(() => {
        if(todosOsProjetos) {
            const aplicarFiltros = async () => {
                let filteredData = [...todosOsProjetos];
            
                if (categoriaSelecionada) {
                    filteredData = filteredData.filter(item => item.category === categoriaSelecionada);
                }
            
                if (anoSelecionado && anoSelecionado.length > 0) {
                    const selectedYears = anoSelecionado.map(option => parseInt(option.value, 10));
                
                    filteredData = filteredData.filter(item => {
                        const year = new Date(item.lastIssueUpdateTime).getFullYear();
                        return selectedYears.includes(year);
                    });
                }
            
                if (liderSelecionado) {
                    filteredData = filteredData.filter(item => item.lead === liderSelecionado);
                }
            
                if (clienteSelecionado) {
                    const projetos = await getProjectByClient(clienteSelecionado);
                    if (projetos.length > 0) {
                        filteredData = filteredData.filter(data =>
                            projetos.some(projeto => projeto.chave === data.key)
                        );
                    } else {
                        alert.error("Nenhum projeto encontrado para esse cliente.");
                        setNoProjects(true);
                        filteredData = [];
                    }
                }
            
                if (filterType.column === 'name') {
                    let filterName = filterType.filter.toUpperCase();
                    filteredData = filteredData.filter(item =>
                        item.name && item.name.toUpperCase().includes(filterName)
                    );
                }

                if (filterType.column === 'key') {
                    let filterName = filterType.filter.toUpperCase();
                    filteredData = filteredData.filter(item =>
                        item.key && item.key.toUpperCase().includes(filterName)
                    );
                }

                if (filterType.column === 'checkbox') {
                    filteredData = filteredData.filter(item => rowsDataChecked.some(item2 => item.name == item2.name));
                }

                if (filterType.column === 'name' || filterType.column === 'key' || filterType.column === 'checkbox' || clienteSelecionado || liderSelecionado || anoSelecionado || categoriaSelecionada) {
                    setFilteredData(filteredData);
                    setVerificarRow(true);
                    setFilterSelecionado(true)
                }

                setRowsData(filteredData);
            };

            aplicarFiltros();
        }
    }, [filterType, categoriaSelecionada, anoSelecionado, liderSelecionado, clienteSelecionado, todosOsProjetos]);

    const getProjectByClient = async (id) => {
        if(id) {
            try {
                const response = await ClienteoApiService.getClientProjectsById(id);
                return response.data.projetos;
            } catch (error) {
                console.error("Erro ao buscar os projetos pelo cliente.", error)
            }
        }
        return []
    };

    const handleChangeCategoria = (e) => {
        if(e.target.value != ""){
            setCategoriaSelecionada(e.target.value);
            
            setIsFilteredBool(true);
        } else{
            setCategoriaSelecionada("");
        }
    };

    const handleChangeAno = (e) => {
        setAnoSelecionado(e);
    }

    const handleChangeLider = (e) => {
        if(e.target.value != ""){
            setLiderSelecionado(e.target.value);
            setVerificarRow(true);
            setIsFilteredBool(true);
        } else{
            setLiderSelecionado("");
        }
    }

    const handleChangeCliente = async (e) => {
        setNameClienteSelecionado(e.target.value);
        if(e.target.value != ""){
            try {
                const response = await ClienteoApiService.getIdClient(e.target.value);
                const id = response.data.id;
                setClienteSelecionado(id);
            } catch (error) {
                console.error("Não foi possível buscar o id do cliente.", error)
            } finally {                
                setVerificarRow(true);
                setIsFilteredBool(true);
            }
        } else{
            setClienteSelecionado("");
        }
    };

    const columnsListTopbar = columnsNotFilter ? columns.filter(c => !columnsNotFilter.includes(c.name)) : columns;
    const stateBoll = false

    return (
        <React.Fragment>

            {onSubmitFilter &&
            <>
                { todosOsProjetos && todosLideres && todosClientes ?
                <div className="option-filter">
                    <Form.Group className='select-item__ano'>
                        <Select
                            components={animatedComponents}
                            isMulti
                            options={listaAnos}
                            value={anoSelecionado}
                            onChange={(e)=>{handleChangeAno(e)}}
                            closeMenuOnSelect={true}
                            placeholder={"anos"}
                        />
                    </Form.Group>
                    <Form.Group className='select-item'>
                        <Form.Control
                            as="select"
                            name="cliente"
                            value={nameClienteSelecionado}
                            onChange={(e)=> handleChangeCliente(e)}
                        >
                        <option value="">--Cliente--</option>

                        {todosClientes?.map(cliente => (
                            <option key={cliente.id} value={cliente.key}>{cliente.name}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='select-item'>
                        <Form.Control
                            as="select"
                            name="lider"
                            value={liderSelecionado}
                            onChange={(e)=> handleChangeLider(e)}
                        >
                        <option value="">--Lider--</option>

                        {todosLideres?.map(lider => (
                            <option key={lider} value={lider}>{lider}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='select-item'>
                        <Form.Control
                            as="select"
                            name="categoria"
                            value={categoriaSelecionada}
                            onChange={(e)=> handleChangeCategoria(e)}
                        >
                        <option value="">--Categoria--</option>

                        {rowsDataCategorias.map(categoria => (
                            <option key={categoria.id} value={categoria.name}>{categoria.name}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    {columnsOptionsFilterExtras && createOptionsFilterExtras(columnsOptionsFilterExtras, onSubmitFilter)}
                    {/* <ListTopbar {...{ columnsListTopbar, onSubmitFilter, actions, firstColumn, optionInitialFilter, multiFilter, isFilteredBool, setVerificarRow, setIsFilteredBool, noProjects}} /> */}
                    < ListTopbarCustom {...{ columnsListTopbar, optionInitialFilter, multiFilter, setFilterType }} />
                    {filterSelecionado && 
                        <Button
                            as="a"
                            onClick={() => {setClienteSelecionado(""); setAnoSelecionado(""); setCategoriaSelecionada(""); setLiderSelecionado(""); setFilterSelecionado(false); getDataList(); setVerificarRow(false);}}
                            variant="link"
                        >
                            <FaEraser />{' Limpar filtro'}
                        </Button>
                    }
                </div>
                :
                <>  
                    <ListTopbar {...{ columnsListTopbar, onSubmitFilter, actions, firstColumn, optionInitialFilter, multiFilter, isFilteredBool, setVerificarRow, setIsFilteredBool}} />
                </>
                }
                </>
            }

            <div className="table-responsive">
                <Table
                    bordered={bordered}
                    striped={swipeColor}
                    hover={hover}
                    responsive
                    bsPrefix="table"
                    className="table table-content"
                >
                    {/* TABLE HEADER */}
                    {(Array(columns).length > 0) &&
                        <thead>
                            <tr>
                                {columns.map((item, idx) => {
                                    const thKey = `th-${idx}`;
                                    const colSpan = item.colSpan || 0;
                                    const className = ['list-header', item.className || ''].join(' ');
                                    const style = {
                                        ...item.onClick ? { cursor: 'pointer' } : {},
                                        ...item.style || {}
                                    };
                                    // const width = item.width ? item.width : {};
                                    const ordered = item.ordered ? item.ordered : '';

                                    return (
                                        <th key={thKey}
                                            onClick={item.onClick}
                                            colSpan={colSpan}
                                            className={className}
                                            style={style}
                                            {...item.width && { width: item.width }}
                                            {...item.attr && item.attr} // atributos extras para table header
                                        >
                                            {item.label == "Carga horária" ?  <>{item.label} <CustomTooltip key={item.label} label={"Se a carga horária estiver vazia, o valor padrão será 8h."} customStylePopup={stylePopup} /> </>: item.label}
                                            {ordered}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                    }

                    {/* TABLE BODY */}
                    <tbody>
                        {rows.length === 0 && (
                            <tr><td colSpan={columns.length}>
                                <NothingToShow />
                            </td></tr>
                        )}

                        {rows.map((row, k) => {
                            const trKey = `tr-${k}`;
                            const className = ['list-row', row.className || '', cursorPointer ? 'cursor-pointer-row' : ''].join(' ');
                            const style = {
                                ...row.onClick ? { cursor: 'pointer' } : {},
                                ...row.style || {}
                            }

                            return (
                                <tr key={trKey}
                                    onClick={row.onClick}
                                    className={className}
                                    style={style}
                                >
                                    {row.data.map((field, fidx) => {
                                        const tdKey = `${trKey}-td-${fidx}`;
                                        const colSpan = field.colSpan || 0;
                                        const tdClassName = ['list-row-cell', field.className].join(' ');
                                        const tdStyle = {
                                            ...field.onClick ? { cursor: 'pointer' } : {},
                                            ...field.style || {}
                                        }

                                        if (field.actions && field.actions.type === 'checkbox') {
                                            return (
                                                <td key={tdKey}>
                                                    <input
                                                        id={row.data[0].value}
                                                        type={field.actions.type}
                                                        name={row.data[0].value}
                                                        value={row.data[0].value}
                                                        defaultChecked={field.actions.checked || false}
                                                        // defaultChecked={false}
                                                        onClick={() => field.actions.onclick({
                                                            id: row.data[0].value,
                                                            checked: document.getElementById(row.data[0].value).checked
                                                        })}
                                                    />
                                                </td>
                                            )

                                        }

                                        return (
                                            <td key={tdKey}
                                                onClick={onRowClick || field.onClick}
                                                colSpan={colSpan}
                                                className={tdClassName}
                                                style={tdStyle}
                                                {...field.attr && field.attr}
                                            >
                                                {field.value}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

// PROPTYPES
ListLayout.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            className: PropTypes.string,
            colSpan: PropTypes.oneOf([
                PropTypes.string,
                PropTypes.number,
                PropTypes.any
            ]),
            onClick: PropTypes.func,
            style: PropTypes.object,
            ordered: PropTypes.string,
            // width: PropTypes.oneOf([
            //     PropTypes.string,
            //     PropTypes.number,
            //     PropTypes.any
            // ]),
        }),
    ),
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            onClick: PropTypes.func,
            style: PropTypes.object,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.any.isRequired,
                    className: PropTypes.string,
                    colSpan: PropTypes.oneOf([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.any
                    ]),
                    onClick: PropTypes.func,
                    style: PropTypes.object
                })
            )
        })
    ),
    onSubmitFilter: PropTypes.func,
    bordered: PropTypes.bool,
    swipeColor: PropTypes.bool,
    hover: PropTypes.bool,
    // actions: PropTypes.oneOfType([
    //     PropTypes.array
    // ]),
};

// DEFAULT PROPS
ListLayout.defaultProps = {
    bordered: false,
    swipeColor: true,
    hover: true,
    onRowClick: null,
    onSubmitFilter: null,
    columns: [],
    rows: [],
    actions: [],
    cursorPointer: false
};

export default ListLayout;
