import DepartamentoApiService from "../../../../services/api/DepartamentoApiService";
import FilialApiService from "../../../../services/api/FilialApiService";
import FuncaoApiService from '../../../../services/api/FuncaoApiService';
import ProjetosApiService from "../../../../services/api/ProjetosApiService";
import RegimeApiService from '../../../../services/api/RegimeApiService';
import UtilService from "../../../../services/util";
import store from '../../../../store';
import { 
    storeDepartamentos, storeFiliais, storeFuncoes, storeRegimes, storeClassificacao 
} from "../../../../store/actions/dadosActions";

/** 
 * Informações das colunas para lista de colaboradores
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      shouldRender: Function,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>} 
*/
export const getFormFields = async () => {
    // REDUX
    const { dados } = store.getState();
    
    const [ filialData, deptData, funcaoData, regimeData, classificacaoData ] = await Promise.all([
        dados.filiais || (FilialApiService.getAll()).then(resp => resp.data.data),
        dados.departamentos || (DepartamentoApiService.getAll()).then(resp => resp.data.data),
        dados.funcoes || (FuncaoApiService.getAll()).then(resp => resp.data.data),
        dados.regimes || (RegimeApiService.getAll()).then(resp => resp.data.data),
        dados.classificacoes || (ProjetosApiService.getCategories().then(resp => 
            resp.data.data.filter(item => 
                item.name != "Financiado" && 
                item.name != "Projetos Internos" && 
                item.name != "Produtos"
            ))
        )
    ]);

    // UPDATE REDUX ?
    if (!dados.filiais) {
        store.dispatch(storeFiliais(filialData))
    }
    if (!dados.departamentos) {
        store.dispatch(storeDepartamentos(deptData))
    }
    if (!dados.funcoes) {
        store.dispatch(storeFuncoes(funcaoData))
    }
    if (!dados.regimes) {
        store.dispatch(storeRegimes(regimeData))
    }
    if (!dados.classificacoes) {
        store.dispatch(storeClassificacao(classificacaoData))
    }

    return {
        groups: [
            {
                header: '',
                shouldRender: (form) => {
                    const hasCod = (form.cod ? true : false);
                    return hasCod;
                },
                fields: [
                    {
                        label: 'Matricula',
                        name: 'mat',
                        type: 'text',
                        size: 3,
                        readOnly: true,
                        disabled: true,
                    },
                    /* {
                        label: 'Código',
                        name: 'cod',
                        type: 'text',
                        size: 3,
                        readOnly: true
                    } */
                ]
            },
            {
                header: 'Informações pessoais',
                fields: [
                    {
                        label: 'Nome',
                        placeholder: 'Ex: João',
                        name: 'nome',
                        type: 'text',
                        size: 4,
                    },
                    {
                        label: 'Sobrenome',
                        placeholder: 'Ex: Paulo dos Santos',
                        name: 'sobrenome',
                        type: 'text',
                        size: 6,
                    },
                    {
                        label: 'CPF',
                        placeholder: 'Ex: 12345678901',
                        name: 'cpf',
                        type: 'text',
                        size: 3,
                        attr: (form) => ({
                            pattern: '[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}',
                            'data-inputmask-mask': '999.999.999-99'
                        })
                    },
                    // {
                    //     label: 'Data Nasc.',
                    //     placeholder: '01/02/1980',
                    //     name: 'nascimento',
                    //     type: 'date',
                    //     size: 3,
                    //     max: UtilService.viewDateToApi( new Date().toLocaleDateString('pt-BR') )
                    // },,
                    {
                        label: 'Qtd. Dependentes',
                        placeholder: '',
                        name: 'dependentes',
                        type: 'number',
                        defaultValue: 0,
                        min: 0, 
                        size: 2,
                    },
                ]
            },
            {
                header: 'Informações de Contato', 
                fields: [
                    {
                        label: 'Endereço',
                        placeholder: 'Ex: Rua dos Bobos, nº 0...',
                        name: 'endereco',
                        type: 'text',
                        size: 4,
                    },
                    {
                        label: 'Cidade',
                        placeholder: 'Ex: Rio de Janeiro...',
                        name: 'cidade',
                        type: 'text',
                        size: 4,
                    },
                    {
                        label: 'E-mail',
                        placeholder: 'Ex: example@email.com',
                        name: 'email',
                        type: 'email',
                        size: 4,
                    },
                ]
            },
            {
                header: 'Informações de Contrato de Trabalho', 
                fields: [
                    {
                        label: 'Filial',
                        placeholder: 'Ex: Vertigo Rio',
                        name: 'filial',
                        type: 'select',
                        size: 3,
                        options: [
                            { value: '', label: '...' },
                            ...(filialData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'label'))
                                .map(item => ({
                                    value: item.cod,
                                    label: item.label
                                })
                            )),
                        ]
                    },
                    {
                        label: 'Departamento',
                        placeholder: '',
                        name: 'departamento',
                        type: 'select',
                        size: 3,
                        options: [
                            { value: '', label: '...' },
                            ...(deptData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'nome'))
                                .map(item => ({
                                    value: item.cod,
                                    label: item.nome
                                })
                            ))
                        ]
                    },
                    {
                        label: 'Regime Contrato',
                        placeholder: '',
                        name: 'regime',
                        type: 'select',
                        size: 2,
                        options: [
                            { value: '', label: '...' },
                            ...(regimeData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'nome'))
                                .map(item => ({
                                    value: item.cod,
                                    label: `${item.sigla} - ${item.nome}`
                                }))
                            )
                        ]
                    },
                    {
                        label: 'Função',
                        placeholder: '',
                        name: 'funcao',
                        type: 'select',
                        size: 4,
                        options: [
                            { value: '', label: '...' },
                            ...(funcaoData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'nome'))
                                .map(item => ({
                                    value: item.cod,
                                    label: `${item.cbo} - ${item.nome}`
                                }))
                            )

                        ]
                    },
                    {
                        label: 'Admissão',
                        placeholder: 'dd/mm/aaaa',
                        name: 'admissao',
                        type: 'date',
                        size: 3,
                        max: UtilService.viewDateToApi( new Date().toLocaleDateString('pt-BR') ),
                        attr: (form) => ({
                            pattern: '[0-9]{2}-[0-9]{2}-[0-9]{4}',
                        })
                    },
                    {
                        label: 'Demissão',
                        placeholder: 'dd/mm/aaaa',
                        name: 'demissao',
                        type: 'date',
                        size: 3,
                        max: UtilService.viewDateToApi( new Date().toLocaleDateString('pt-BR') ),
                        attr: (form) => ({
                            pattern: '[0-9]{2}-[0-9]{2}-[0-9]{4}',
                        })
                    },
                    {
                        label: 'Salário',
                        placeholder: '',
                        name: 'salario',
                        type: 'currency',
                        defaultValue: 0,
                        min: 0, 
                        size: 3,
                    },
                    {
                        label: 'Classificação',
                        placeholder: '',
                        name: 'classificacao',
                        type: 'select',
                        size: 3,
                        options: [
                            { value: '', label: '...' },
                            ...(classificacaoData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'nome'))
                                .map(item => ({
                                    value: item.name,
                                    label: item.name
                                }))
                            )
                        ]
                    },
                    {
                        label: 'Carga Horária',
                        placeholder: '',
                        name: 'carga_horaria',
                        type: 'number',
                        defaultValue: 0,
                        min: 0, 
                        size: 2,
                    },
                    {
                        label: 'Última atualização',
                        placeholder: 'dd/mm/aaaa',
                        name: 'data_inicio',
                        type: 'date',
                        size: 2,
                        attr: (form) => ({
                            pattern: '[0-9]{2}-[0-9]{2}-[0-9]{4}',
                        })
                    },
                ]
            },
            {
                header: '',
                fields: [
                    {
                        label: 'Observação',
                        placeholder: 'Digite as observações aqui...',
                        name: 'observacao',
                        type: 'textarea',
                        rows: 4,
                        size: 6,
                        max: 600
                    }
                ]
            }
        ]
    };
}

const ColaboradorNewInfo = { getFormFields };

export default ColaboradorNewInfo;
