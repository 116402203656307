import {
    DADOS_BENEFICIOS,
    DADOS_BENEFICIO_VALOR,
    DADOS_DEPARTAMENTOS,
    DADOS_FILIAIS,
    DADOS_FUNCOES,
    DADOS_REGIMES,
    DADOS_CLASSIFICACAO
} from '../types';

export const storeFiliais = (filiais) => {
    return {
        type: DADOS_FILIAIS,
        payload: filiais
    }
}

export const storeDepartamentos = (departamentos) => {
    return {
        type: DADOS_DEPARTAMENTOS,
        payload: departamentos
    }
}

export const storeFuncoes = (funcoes) => {
    return {
        type: DADOS_FUNCOES,
        payload: funcoes
    }
}

export const storeRegimes = (regimes) => {
    return {
        type: DADOS_REGIMES,
        payload: regimes
    }
}

export const storeClassificacao = (classificacoes) => {
    return {
        type: DADOS_CLASSIFICACAO,
        payload: classificacoes
    }
}

export const storeBeneficios = (beneficios) => {
    return {
        type: DADOS_BENEFICIOS,
        payload: beneficios
    }
}

export const storeBeneficioValor = (beneficioValores) => {
    return {
        type: DADOS_BENEFICIO_VALOR,
        payload: beneficioValores
    }
}
