import React from "react";
import { Button } from 'react-bootstrap';

import UtilService from '../../../../services/util';

function HeaderDetailColaboradores(props) {
    const { dataProjectDetail } = props;

    return (
        <div style={{ marginTop: 30 }}>
            <h4>
                Colaboradores &nbsp;

                {
                    dataProjectDetail.timeSpentUser.length > 0 &&
                    <Button onClick={
                            () => {
                                const header = [['Colaborador', 'Horas Alocadas', 'Custo Médio Hora', 'Custo Total', 'Projeto']];
                                const label= 'Colaboradores';

                                const formatedData = dataProjectDetail.timeSpentUser.map(item => {
                                    return {
                                        user: item.user,
                                        timeLogged: parseFloat(String(item.timeLogged).replace(',', '.')),
                                        custoHH: parseFloat(String(item.custoHH).replace(',', '.')),
                                        custoTotal: parseFloat(String(item.custoTotal).replace(',', '.')),
                                        projeto: item.projeto
                                    }
                                })
                                UtilService.exportXls(formatedData, label, header)
                            }
                        }
                    >
                        Exportar
                    </Button>
                }
            </h4>
        </div>
    );
}

export default HeaderDetailColaboradores;
