import React, { useState } from "react";
import './Tooltip.css'
import { FiInfo } from "react-icons/fi";

const CustomTooltip = ({key, label, customStyle, customStylePopup}) =>{

    const [visiblePopup, setVisiblePopup] = useState(false)

    const handleMouseEnter = (popupId) => {
        setVisiblePopup(popupId);
    };

    const handleMouseLeave = () => {
        setVisiblePopup(null);
    };

    const styleDefault = {
        position: 'relative',
        cursor: 'pointer',
        ...customStyle,
    }

    const stylePopupDefault = {
        ...customStylePopup
    }
    
    return(
        <span 
            onMouseEnter={()=> handleMouseEnter(key)}
            onMouseLeave={handleMouseLeave}
            className="ml-1 info-icon"
            style={styleDefault}
            >
            <FiInfo />
            {visiblePopup === key && (
                <div className="hover-popup popup-style" style={stylePopupDefault}> 
                    {label}
                </div>
            )}
        </span>
    )
}

export default CustomTooltip;