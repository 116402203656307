import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

import PageContainer from '../../../../components/PageContainer';
import ListLayout from '../../../../components/ListLayout';
import Loading from '../../../../components/Loading';
import ErrorRequestService from '../../../../services/errorRequest';

import ColaboradorApiService from '../../../../services/api/ColaboradorApiService';
import DepartamentoApiService from '../../../../services/api/DepartamentoApiService';
import FuncaoApiService from '../../../../services/api/FuncaoApiService';

import useAlertNotification from '../../../../context/AlertNotificationContext';

import UtilService from '../../../../services/util';

import { columns } from './ColaboradorListInfo';
import PlanejamentoApiService from '../../../../services/api/PlanejamentoApiService';

const INITIAL_PAGEPARAMS = {
    hasNext: true,
    page: null,
    pageSize: null,
    orderBy: 'cod',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function ColaboradorList(props) {
    // CONTEXT
    const history = useHistory();
    const match = useRouteMatch();
    const alert = useAlertNotification();

    // STATE
    const [rowsData, setRowsData] = useState(null);
    const [rowsDataDepartamentos, setRowsDataDepartamentos] = useState([]);
    const [rowsDataFuncoes, setRowsDataFuncoes] = useState([]);

    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    const rowClick = (item) => {
        history.push(`${match.url}/${item.cod}`, item);
    }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: () => rowClick(item),
                    data: columns.map(col => ({
                        value: item[col.name],
                        className: col.className
                    }))
                };
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = (params) => {
        const { column, filter, isOptionFilter } = params;
        if (!column || !filter) {
            setFilteredData(rowsData); // filtro local
            return
        }

        // APLICA FILTRO LOCAL
        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            if (isOptionFilter) return parseInt(item[column]) === parseInt(lwFilter) && item;

            return lwData.includes(lwFilter);
        });

        setFilteredData(filtered);
    }

    /** Retorna os colaboradores cadastrados no database  */
    const getDataList = useCallback(async () => {
        try {
            setIsLoading(true);

            const resp = await ColaboradorApiService.getAll().then(r => r.data);
            const { data } = resp;
            setRowsData(data);

            const respDepartamentos = await DepartamentoApiService.getAll().then(r => r.data);
            const dataDepartamentos = respDepartamentos.data;
            setRowsDataDepartamentos(dataDepartamentos);

            const respFuncoes = await FuncaoApiService.getAll().then(r => r.data);
            const dataFuncoes = respFuncoes.data;
            setRowsDataFuncoes(dataFuncoes);

            setFilteredData(data);
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, [orderBy, orderDir]);

    const handleSyncColaboradores = async () =>{
        setIsLoading(true)
        try {
            const resp = await PlanejamentoApiService.syncAllUsersConvertToJira();
        } catch (error) {
            console.error("Erro ao sincronizar os usuários do convert com o Jira/Tempo.", error)
        }
        finally{
            setIsLoading(false)
        }
    }

    // EFFECTS
    useEffect(getDataList, []);


    const pageActions = [
        {
            label: 'Novo',
            icon: <FaPlus />,
            color: 'primary',
            type: 'button',
            href: `${match.path}/new`,
            // onClick: () => history.push(`${match.path}/new`)
        },
        // {
        //     label: 'Sincronizar todos',
        //     icon: "",
        //     color: 'info',
        //     type: 'button',
        //     onClick: () => handleSyncColaboradores()
        // }
    ]

    // // @TODO: Usar estas actions quando lista paginada
    // const listActions = [
    //     {
    //         label: 'Exibir:',
    //         type: 'select',
    //         onChange: (ev) => setPageSize(Number(ev.target.value)),
    //         value: pageSize,
    //         options: [
    //             { value: 5, label: '5' },
    //             { value: 10, label: '10' },
    //             { value: 25, label: '25' }
    //         ]
    //     }
    // ];

    const title = 'Lista de Colaboradores';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : <>
                    <Button
                        className="mb-2"
                        onClick={() => {
                            const header = [
                                [
                                    'Matrícula', 'CPF', 'Nome', 'Sobrenome', 'E-mail', 'Endereço', 'Cidade', 'Dependentes', 'Admissão', 'Demissão', 'Observação', 'Filial', 'Regime', 'Departamento', 'Função', 'Classificação', 'Carga Horária', 'Última atualização'
                                    
                                ]
                            ];

                            const arrayDadosColab =  filteredData ? filteredData : rowsData;

                            const formatedData = arrayDadosColab.map((item, index) => {
                                return{
                                    matricula: item.cod,
                                    cpf: item.cpf,
                                    nome: item.nome,
                                    sobrenome: item.sobrenome,
                                    email: item.email,
                                    endereco: item.endereco,
                                    cidade: item.cidade,
                                    dependentes: item.dependentes,
                                    admissao: item.admissao,
                                    demissao: item.demissao,
                                    observacao: item.observacao,
                                    filial: item.filial,
                                    regime: item.regime,
                                    departamento: item.departamento,
                                    funcao: item.funcao,
                                    classificacao: item.classificacao,
                                    carga_horaria: item.carga_horaria,
                                    data_inicio: item.data_inicio
                                }
                            })

                            UtilService.exportXls(formatedData, title, header);
                        }}
                    >Exportar</Button>

                    <ListLayout
                        columns={getListHeader()}
                        rows={defineDataList()}
                        onSubmitFilter={filterList}
                        // actions={hasNext && listActions}
                        columnsOptionsFilterExtras={[
                            {
                                tipo: '--Departamentos--',
                                column: 'departamento',
                                filter: 'cod',
                                label: 'nome',
                                data: rowsDataDepartamentos
                            },
                            {
                                tipo: '--Funções--',
                                column: 'funcao',
                                filter: 'cod',
                                label: 'nome',
                                data: rowsDataFuncoes
                            }
                        ]}
                    />
                </>
            }

        </PageContainer>
    )
}

export default ColaboradorList;
