import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import React, { useEffect, useState, useCallback } from "react";
import PageContainer from "../../../components/PageContainer";
import { useLocation } from 'react-router-dom';
import { MdOutlineArrowBack } from "react-icons/md";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PlanejamentoApiService from "../../../services/api/PlanejamentoApiService";
import ProjetosApiService from "../../../services/api/ProjetosApiService";
import Loading from "../../../components/Loading";
import { Table, Dropdown, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import ColaboradorApiService from "../../../services/api/ColaboradorApiService";
import ConvertDataService from "../../../services/util/ConvertDatas";
import UtilService from "../../../services/util";
import FuncaoApiService from "../../../services/api/FuncaoApiService";
import { FiInfo } from "react-icons/fi";

const popupStyle = {
    position: 'absolute',
    top: '-250%',
    left: '-600%',
    transform: 'translateX(-80%)',
    backgroundColor: '#e9e9e9',
    color: '#212529',
    border: '1px solid #dee2e6',
    borderRadius: '5px',
    padding: '5px 10px',
    zIndex: 1000,
    whiteSpace: 'nowrap',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    width: 'auto',
    fontWeight: 'normal'
};

function RelatorioCapacidadeProjetoView() {

    const [loading, setLoading] = useState(false);
    const [funcoes, setFuncoes] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedRegime, setSelectedRegime] = useState('');
    const location = useLocation();
    const { data } = location.state || {};
    const history = useHistory();
    const [visiblePopup, setVisiblePopup] = useState(null);
    const [sortDirection, setSortDirection] = useState({});

    const [projetosComColaboradores, setProjetosComColaboradores] = useState([]); 
    const [fetchDadosProjetosColaboradores, setFetchDadosProjetosColaboradores] = useState([]);
    const [projetosColaboradoresComHoras, setProjetosColaboradoresComHoras] = useState([]);    
    const [filteredProjetosColaboradores, setFilteredProjetosColaboradores] = useState([]);
    const [ordenacaoProjetosColaborador, setOrdenacaoProjetosColaborador] = useState([]);
    const [horasRealizadas, setHorasRealizadas] = useState([]);
    const [horasPlanejadas, setHorasPlanejadas] = useState([]);

    const dataInicialFiltro = ConvertDataService.convertDataSplitYYYYmmDD(data.periodoInicial);
    const dataFinalFiltro = ConvertDataService.convertDataSplitYYYYmmDD(data.periodoFinal);

    const fetchColaboradores = useCallback(async () => {

        setLoading(true)       
        let resp;
        try {
            resp = (await ColaboradorApiService.getAll()).data.data;
        } catch (error) {
            console.error("Erro ao carregar colaboradores.", error);
            return;
        }
        let planejadas = []
        let realizadas = []
        const projects = data.projetosSelecionados;
        const queue = [];
        for (let i = 0; i < projects.length; i += 5) {
            const chunk = projects.slice(i, i + 5);
            const promises = chunk.map(async (project) => {
                let parametros = [
                    dataInicialFiltro,
                    dataFinalFiltro,
                    project.id
                ];
                const planejadasProjetos = await PlanejamentoApiService.getPlansByProjectAndDate(parametros).then(res => res.data.filter(plan => plan.planItem.id === project.id));
                const realizadasProjetos = await PlanejamentoApiService.getProjectWorklogByUserAndDate(parametros).then(res => res.data)
                const colabs = resp.filter(colab => planejadasProjetos.map(plan => plan.assignee.id).includes(colab.accountId) || 
                realizadasProjetos.map(real => real.author.accountId).includes(colab.accountId))
                const colabsFiltroDemissao = colabs.filter(colab => !colab.demissao || colab.demissao >= dataInicialFiltro)
                planejadas = [...planejadas, ...planejadasProjetos]
                realizadas = [...realizadas, ...realizadasProjetos]
                return {projeto: project, colaboradores: colabsFiltroDemissao}
            });
            queue.push(...(await Promise.all(promises)));
        }
        setHorasPlanejadas(planejadas)
        setHorasRealizadas(realizadas)
        setProjetosComColaboradores(queue)
        setLoading(false)
    }, [dataInicialFiltro, dataFinalFiltro]);

    const fetchFuncoes = useCallback(async () => {
        try {
            const resp = await FuncaoApiService.getAll();
            setFuncoes(resp.data.data);
        } catch (error) {
            console.error("Erro ao buscar as funções.", error);
        }
    }, []);

    useEffect(() => {
        fetchColaboradores();
        fetchFuncoes();
    }, [fetchColaboradores, fetchFuncoes]);

    async function fetchDataForCollaboratorInProject(item) {
        const colaboradores = item.colaboradores;
        const queue = [];
        for (let i = 0; i < colaboradores.length; i += 5) {
            const chunk = colaboradores.slice(i, i + 5);
            const promises = chunk.map(async (colaborador) => {
                setLoading(true)
                
                let planejadas, obrigatorias, realizadas, cargaHoraria;
            
                    planejadas = horasPlanejadas.filter(plan => plan.planItem.id === item.projeto.id && plan.assignee.id === colaborador.accountId);
            
                try {
                    obrigatorias = await PlanejamentoApiService.getUserScheduleByUserAndDate([
                        colaborador.accountId,
                        new Date(colaborador.admissao) > new Date(dataInicialFiltro) ? colaborador.admissao : dataInicialFiltro,
                        colaborador.demissao ? colaborador.demissao : dataFinalFiltro
                    ]).then(res => res.data.results);
                } catch (error) {
                    console.error(`Erro ao buscar horas obrigatórias para ${colaborador.nome}:`, error);
                    obrigatorias = [];
                }
            
                try {
                    realizadas = horasRealizadas.filter(worklog => worklog.author.accountId === colaborador.accountId && worklog.projectId === item.projeto.id)
                } catch (error) {
                    console.error(`Erro ao buscar worklogs para ${colaborador.nome}:`, error);
                    realizadas = [];
                }
        
                try{
                    cargaHoraria = await PlanejamentoApiService.getUserWorkload(colaborador.accountId)
                    .then(res => res.data)
                }   catch(error){
                    console.error(`Erro ao buscar carga horária para ${colaborador.nome}: `, error)
                    cargaHoraria = [];
                }
                return {colaborador, planejadas, obrigatorias, realizadas, cargaHoraria }
            });
            queue.push(...(await Promise.all(promises)));
        }
        return {projeto: item.projeto, colaboradores: queue};
    }

    async function fetchProjectsWithLimit(projects, limit) {
        const queue = [];
        for (let i = 0; i < projects.length; i += limit) {
            const chunk = projects.slice(i, i + limit);
            const promises = chunk.map(project => fetchDataForCollaboratorInProject(project));
            queue.push(...(await Promise.all(promises)));
        }
        setFetchDadosProjetosColaboradores(queue);
        setLoading(false)
        return queue;
    }

    useEffect(() => {
        if (projetosComColaboradores.length > 0) {
            fetchProjectsWithLimit(projetosComColaboradores, 5)
        }
    }, [projetosComColaboradores]);

    useEffect(() => {
        if (Array.isArray(fetchDadosProjetosColaboradores) && fetchDadosProjetosColaboradores.length > 0) {
    
            const resultadosFetch = fetchDadosProjetosColaboradores.map((item) => {
                const { projeto, colaboradores } = item;

                const colabs = colaboradores.map(colab => {
                
                    const { colaborador, planejadas, obrigatorias, realizadas, cargaHoraria } = colab;

                    let horasPlanejadas = planejadas.reduce((acc, plan) => acc + (plan.totalPlannedSecondsInScope || 0), 0) / 3600;

                    let cargaHorariaTotal = (cargaHoraria.days[0].requiredSeconds);

                    let horasPorDia = cargaHorariaTotal == 60 ? 0 : cargaHorariaTotal / 3600; 
                
                    let totalHorasObrigatorias = obrigatorias
                        .filter(result => result.type === "WORKING_DAY")
                        .reduce((acc, result) => acc + (result.requiredSeconds || 0), 0) / 3600;
                
                    let duracao = realizadas.reduce((acc, worklog) => acc + (worklog.timeSpentSeconds || 0), 0) / 3600;
                    let horasFaturaveis = realizadas.reduce((acc, worklog) => acc + (worklog.billableSeconds || 0), 0) / 3600;

                    let horasLivres = totalHorasObrigatorias.toFixed() - horasPlanejadas.toFixed();

                    return {
                        colaborador, 
                        planejadas, 
                        obrigatorias, 
                        realizadas, 
                        cargaHoraria,
                        horasPorDia,
                        totalPlannedHoursInScope: horasPlanejadas,
                        totalHorasObrigatorias,
                        duracao,
                        horasFaturaveis,
                        horasLivres
                    };
                })
                return {projeto: projeto, colaboradores: colabs}
            });
            setProjetosColaboradoresComHoras(resultadosFetch);
        }
    }, [fetchDadosProjetosColaboradores]);

    useEffect(() => {
        const filtered = projetosColaboradoresComHoras.map(item => {
            const { projeto, colaboradores } = item;

            const colabs = colaboradores.filter(colab => {
                    const matchesDepartment = selectedDepartment
                        ? colab.colaborador.departamento === (selectedDepartment === 'Delivery' ? 5 : selectedDepartment === 'ENGESOL' ? 16 : 20)
                        : true;
                    const matchesRegime = selectedRegime
                        ? colab.colaborador.regime === (selectedRegime === 'CLT' ? 1 : 3)
                        : true;
                    return matchesDepartment && matchesRegime
                        
            })
            return {projeto: projeto, colaboradores: colabs}
        }); 
        setFilteredProjetosColaboradores(filtered);
    }, [selectedDepartment, selectedRegime, projetosColaboradoresComHoras]);

    useEffect(() => {
        const filtered = filteredProjetosColaboradores.map(item => {
            const { projeto, colaboradores } = item;
            return {projeto: projeto, colaboradores: colaboradores.sort((a, b) => a.colaborador.nome.localeCompare(b.colaborador.nome))}
        }); 
        setOrdenacaoProjetosColaborador(filtered);
    },[filteredProjetosColaboradores])
    

    const exportDataInExcel = async () => {
        const header = [
            [
                'Colaborador', "Projeto", "Categoria", 'Status', 'Departamento', 'Regime', 'Função', 'Carga Horária Diária', 'Total Horas Obrigatórias', 'Total Horas Planejadas', 'Horas Livres Planejadas', 'Ocupação Planejada(%)', 'Total de Horas Realizadas', 'Ocupação Realizada (%)', 'Total de Horas Faturáveis', 'Ocupação Faturável (%)', 'Variação Faturável (%)'
            ]
        ];

        const formatedData = filteredProjetosColaboradores.map(item => {
            const colabs =  item.colaboradores.map((colab) => {
                const cargaHorariaDiaria = parseFloat(colab.horasPorDia.toFixed())

                const desvio = colab.totalPlannedHoursInScope === 0
                    ? -100
                    : ((colab.totalPlannedHoursInScope - colab.horasFaturaveis) / colab.totalPlannedHoursInScope) * 100;

                return {
                    colaborador: `${colab.colaborador.nome} ${colab.colaborador.sobrenome}`,
                    projeto: item.projeto.name,
                    categoria: item.projeto.category,
                    status: colab.colaborador.demissao ? "Inativo" : "Ativo",
                    departamento: colab.colaborador.departamento === 5 ? "Delivery" : colab.colaborador.departamento === 16 ? "ENGESOL" : "Overhead",
                    regime: colab.colaborador.regime === 1 ? "CLT" : colab.colaborador.regime === 3 && "Estagiário",
                    perfil: funcoes.find(funcao => funcao.cod === colab.colaborador.funcao)?.nome,
                    cargaHorariaDiaria,
                    totalHorasObrigatorias: `${colab.totalHorasObrigatorias.toFixed()}h`,
                    totalHorasPlanejadas: `${colab.totalPlannedHoursInScope.toFixed()}h`,
                    horasLivres: `${Math.floor(colab.horasLivres)}h`,
                    ocupacaoPlanejada: colab.colaborador.demissao ? "-- " : `${((colab.totalPlannedHoursInScope * 100)/colab.totalHorasObrigatorias).toFixed()}%`,
                    totalHorasRealizadas: `${colab.duracao.toFixed()}h`,
                    ocupacaoRealizada: `${((colab.duracao * 100)/colab.totalHorasObrigatorias).toFixed()}%`,
                    totalHorasFaturaveis: `${colab.horasFaturaveis.toFixed()}h`,
                    ocupacaoFaturavel: `${((colab.horasFaturaveis * 100)/colab.totalHorasObrigatorias).toFixed()}%`,
                    variacaoFaturavel: parseFloat(desvio.toFixed(2))
                };
            })
            return colabs;
        });

        UtilService.exportXls(formatedData.flat(), `Relatório Capacidade: ${data.periodoInicial} - ${data.periodoFinal}`, header);
    };

    const handleOrdenar = (key, project) => {
        let direction = sortDirection[key] || "cima";
        const filtered = filteredProjetosColaboradores.map(item => {
            const { projeto, colaboradores } = item;
            if(projeto.id == project.projeto.id){
                const sortedColaboradores = [...colaboradores].sort((a, b) => {
                    if (key.includes("colaborador")) {
                        return direction.includes("cima") ? a.colaborador.nome.localeCompare(b.colaborador.nome) : b.colaborador.nome.localeCompare(a.colaborador.nome);
                    } else if (key.includes("departamento")) {
                        return direction.includes("cima") ? a.colaborador.departamento - b.colaborador.departamento : b.colaborador.departamento - a.colaborador.departamento;
                    } else if (key.includes("regime")) {
                        return direction.includes("cima") ? a.colaborador.regime - b.colaborador.regime : b.colaborador.regime - a.colaborador.regime;
                    } else if (key.includes("perfil")) {
                        return direction.includes("cima") ? a.colaborador.funcao - b.colaborador.funcao : b.colaborador.funcao - a.colaborador.funcao;
                    } else if (key.includes("cargaHoraria")) {
                        return direction.includes("cima") ? a.horasPorDia - b.horasPorDia : b.horasPorDia - a.horasPorDia;
                    } else if (key.includes("ocupacaoPlanejada")) {
                        const ocupPlanA = ((a.totalPlannedHoursInScope * 100) / a.totalHorasObrigatorias);
                        const ocupPlanB = ((b.totalPlannedHoursInScope * 100) / b.totalHorasObrigatorias);
                        return direction.includes("cima") ? ocupPlanA - ocupPlanB : ocupPlanB - ocupPlanA;
                    } else if (key.includes("horasObrigatorias")) {
                        return direction.includes("cima") ? a.totalHorasObrigatorias - b.totalHorasObrigatorias : b.totalHorasObrigatorias - a.totalHorasObrigatorias;
                    } else if (key.includes("horasLivres")) {
                        return direction.includes("cima") ? a.horasLivres - b.horasLivres : b.horasLivres - a.horasLivres;
                    } else if (key.includes("horasPlanejadas")) {
                        return direction.includes("cima") ? a.totalPlannedHoursInScope - b.totalPlannedHoursInScope : b.totalPlannedHoursInScope - a.totalPlannedHoursInScope;
                    } else if (key.includes("horasRealizadas")) {
                        return direction.includes("cima") ? a.duracao - b.duracao : b.duracao - a.duracao;
                    } else if (key.includes("ocupacaoRealizada")) {
                        const ocupRealA = ((a.duracao * 100) / a.totalHorasObrigatorias);
                        const ocupRealB = ((b.duracao * 100) / b.totalHorasObrigatorias);
                        return direction.includes("cima") ? ocupRealA - ocupRealB : ocupRealB - ocupRealA;
                    } else if (key.includes("horasFaturaveis")) {
                        return direction.includes("cima") ? a.horasFaturaveis - b.horasFaturaveis : b.horasFaturaveis - a.horasFaturaveis;
                    } else if (key.includes("ocupacaoFaturavel")) {
                        const ocupFatuA = ((a.horasFaturaveis * 100) / a.totalHorasObrigatorias);
                        const ocupFatuB = ((b.horasFaturaveis * 100) / b.totalHorasObrigatorias);
                        return direction.includes("cima") ? ocupFatuA - ocupFatuB : ocupFatuB - ocupFatuA;
                    } else if (key.includes("variacaoFaturavel")) {
                        const desvioA = a.totalPlannedHoursInScope === 0
                            ? -100
                            : (((a.totalPlannedHoursInScope) - a.horasFaturaveis) / (a.totalPlannedHoursInScope)) * 100;
            
                        const desvioB = b.totalPlannedHoursInScope === 0
                            ? -100
                            : (((b.totalPlannedHoursInScope) - b.horasFaturaveis) / (b.totalPlannedHoursInScope)) * 100;
            
                        return direction.includes("cima") ? desvioA - desvioB : desvioB - desvioA;
                    }
                });
                return {projeto: projeto, colaboradores: sortedColaboradores}
            }
            return {projeto: projeto, colaboradores: colaboradores}
        }); 

        direction = direction.includes("cima") ? "baixo" : "cima";
        setSortDirection({ ...sortDirection, [key]: direction });
    
        setOrdenacaoProjetosColaborador(filtered);
    };

    const SortIcon = ({ column, sortDirection }) => {
        if (!sortDirection[column]) {
            return <FaSort className="ml-1" style={{ opacity: 0.3 }} />;
        }
        return sortDirection[column].includes("cima") ? 
            <FaSortUp className="ml-1" /> : 
            <FaSortDown className="ml-1" />;
    };

    const totalPlannedHoursInScopeProjetos = (project) => {
        return project.colaboradores.reduce((total, item) => {
        return total + item.totalPlannedHoursInScope;
    }, 0)}

    const totalHorasObrigatoriasProjetos = (project) => {
        return project.colaboradores.reduce((total, item) => {
        return total + item.totalHorasObrigatorias;
    }, 0)}

    const totalDuracaoProjetos = (project) => {
        return project.colaboradores.reduce((total, item) => {
        return total + item.duracao;
    }, 0)}

    const totalHorasFaturaveisProjetos = (project) => {
        return project.colaboradores.reduce((total, item) => {
        return total + item.horasFaturaveis;
    }, 0)}

    const totalHorasLivresProjetos = (project) => {
        return project.colaboradores.reduce((total, item) => {
        return total + item.horasLivres;
    }, 0)}

    const calcularTotaisGerais = () => {
        return ordenacaoProjetosColaborador.reduce((totais, proj) => {
            const totalHorasObrigatorias = totalHorasObrigatoriasProjetos(proj);
            const totalPlannedHoursInScope = totalPlannedHoursInScopeProjetos(proj);
            const totalHorasLivres = totalHorasLivresProjetos(proj);
            const totalDuracao = totalDuracaoProjetos(proj);
            const totalHorasFaturaveis = totalHorasFaturaveisProjetos(proj);
    
            return {
                totalHorasObrigatorias: totais.totalHorasObrigatorias + totalHorasObrigatorias,
                totalPlannedHoursInScope: totais.totalPlannedHoursInScope + totalPlannedHoursInScope,
                totalHorasLivres: totais.totalHorasLivres + totalHorasLivres,
                totalDuracao: totais.totalDuracao + totalDuracao,
                totalHorasFaturaveis: totais.totalHorasFaturaveis + totalHorasFaturaveis,
            };
        }, {
            totalHorasObrigatorias: 0,
            totalPlannedHoursInScope: 0,
            totalHorasLivres: 0,
            totalDuracao: 0,
            totalHorasFaturaveis: 0,
        });
    };

    const totaisGerais = calcularTotaisGerais();

    const riscoDemissao = {
        textDecoration: "line-through"
    }

    const handleCancel = () => {
        history.push('../relatorios-delivery/');
    };

    const actions = [
        {
            label: 'Voltar',
            icon: <MdOutlineArrowBack />,
            color: 'info',
            type: 'button',
            onClick: handleCancel
        }
    ];

    const fixarTitulo = {
        "position": "sticky", 
        "top":0, 
        "zIndex": 9998, 
        "backgroundColor" : "#f5f5f5",
    }

    const fixarThead = { 
        position: "sticky", 
        top: 0, 
        zIndex: 1000, 
        backgroundColor: "#f5f5f5", 
        "boxShadow" :" 0 4px 4px -4px rgba(0, 0, 0, 0.3)" 
    }

    const handleMouseEnter = (popupId) => {
        setVisiblePopup(popupId);
    };

    const handleMouseLeave = () => {
        setVisiblePopup(null);
    };

    return (
        <>
        <div style={fixarTitulo}>
            <PageContainer title={"Relatório Capacidade por Projeto"} actions={actions}>
                <h5>Período Inicial: {data.periodoInicial}</h5>
                <h5 className="mb-4">Período Final: {data.periodoFinal}</h5>
                {data.cliente && <h5 className="mb-4">Cliente: {data.cliente}</h5>}
                
                <div className="d-flex" style={{position: "sticky"}}>
                <Button onClick={() => exportDataInExcel()} className='mr-3'>Exportar</Button>
                <Dropdown className="mr-3">
                    <Dropdown.Toggle variant="light" id="dd-column">
                        Filtrar por Departamento: <span style={{ fontWeight: 'bold' }}>{selectedDepartment ? selectedDepartment : "Todos"}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelectedDepartment('Delivery')}>
                            Delivery
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('ENGESOL')}>
                            ENGESOL
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('Overhead')}>
                            Overhead
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedDepartment('')}>
                            Todos
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Dropdown>
                    <Dropdown.Toggle variant="light" id="dd-column">
                        Filtrar por Regime: <span style={{ fontWeight: 'bold' }}>{selectedRegime  ? selectedRegime : "Todos"}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelectedRegime('CLT')}>
                            CLT
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedRegime('Estagiário')}>
                            Estagiário
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectedRegime('')}>
                            Todos
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            </PageContainer>
            </div>
                {loading ? <Loading message={"Carregando..."} /> : 
                (ordenacaoProjetosColaborador.length > 0 && !loading ? (
                    <div>
                        <Table striped bordered hover style={{background: "#e1eaf3"}}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Total horas obrigatorias</th>
                                    <th>Total horas Planejadas</th>
                                    <th>Total horas Realizadas</th>
                                    <th>Total horas Faturáveis</th>
                                    <th>Total Ocupação Faturável</th>
                                </tr>
                                
                            </thead>
                            <tbody>
                                <tr style={{background: "transparent"}}>
                                    <td><strong>Total</strong></td>
                                    <td><strong>{totaisGerais.totalHorasObrigatorias.toFixed()}h</strong></td> 
                                    <td><strong>{totaisGerais.totalPlannedHoursInScope.toFixed()}h</strong></td>
                                    <td><strong>{totaisGerais.totalDuracao.toFixed()}h</strong></td>
                                    <td><strong>{totaisGerais.totalHorasFaturaveis.toFixed()}h</strong></td>
                                    <td><strong>{isNaN(((totaisGerais.totalHorasFaturaveis * 100)/totaisGerais.totalHorasObrigatorias).toFixed()) ? "0" : ((totaisGerais.totalHorasFaturaveis * 100)/totaisGerais.totalHorasObrigatorias).toFixed()}%</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                        {ordenacaoProjetosColaborador.map((proj) =>{
                            return <div id="tabela-personalizada-relatorio-capacidade" style={{maxHeight: "600px", overflowY: "auto"}}>
                            <h5 className='mt-3'><strong>Projeto:</strong> {proj.projeto.name}</h5>
                            <h5 className="mb-4"><strong>Categoria:</strong> {proj.projeto.category}</h5>
                            <Table striped bordered hover responsive="xl">
                            <thead style={fixarThead}>
                                <tr>
                                <th className="fixed-column no-wrap" onClick={() => handleOrdenar(`colaborador-${proj.projeto.value}`, proj)}>Colaborador <SortIcon column={`colaborador-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap" onClick={() => handleOrdenar(`departamento-${proj.projeto.value}`, proj)}>Departamento <SortIcon column={`departamento-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap" onClick={() => handleOrdenar(`regime-${proj.projeto.value}`, proj)}>Regime do contrato <SortIcon column={`regime-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap" onClick={() => handleOrdenar(`perfil-${proj.projeto.value}`, proj)}>Função <SortIcon column={`perfil-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap" onClick={() => handleOrdenar(`cargaHoraria-${proj.projeto.value}`, proj)}>Cargas horárias <br/> diárias <SortIcon column={`cargaHoraria-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap" onClick={() => handleOrdenar(`horasObrigatorias-${proj.projeto.value}`, proj)}>Total horas <br/> Obrigatórias <SortIcon column={`horasObrigatorias-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap verde-claro-planejado" onClick={() => handleOrdenar(`horasPlanejadas-${proj.projeto.value}`, proj)}>Total horas <br/> Planejadas <SortIcon column={`horasPlanejadas-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap verde-claro-planejado" onClick={() => handleOrdenar(`horasLivres-${proj.projeto.value}`, proj)}>Horas Livres <br/> Planejadas <SortIcon column={`horasLivres-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap verde-claro-planejado" onClick={() => handleOrdenar(`ocupacaoPlanejada-${proj.projeto.value}`, proj)}>Ocupação <br/> Planejada (%) <SortIcon column={`ocupacaoPlanejada-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar(`horasRealizadas-${proj.projeto.value}`, proj)}>Total horas <br/> Realizadas <SortIcon column={`horasRealizadas-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar(`ocupacaoRealizada-${proj.projeto.value}`, proj)}>Ocupação <br/> Realizada (%) <SortIcon column={`ocupacaoRealizada-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar(`horasFaturaveis-${proj.projeto.value}`, proj)}>Total horas <br/> Faturáveis <SortIcon column={`horasFaturaveis-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap azul-claro-realizado" onClick={() => handleOrdenar(`ocupacaoFaturavel-${proj.projeto.value}`, proj)}>Ocupação <br/> Faturável (%) <SortIcon column={`ocupacaoFaturavel-${proj.projeto.value}`} sortDirection={sortDirection} /></th>
                                <th className="no-wrap cinza-claro-variacao" onClick={() => handleOrdenar(`variacaoFaturavel-${proj.projeto.value}`, proj)}>Variação <br/> Faturável (%)
                                    <span 
                                    onMouseEnter={()=> handleMouseEnter('variacao_faturavel')}
                                    onMouseLeave={handleMouseLeave}
                                    className="ml-1 info-icon"
                                    style={{ position: 'relative', cursor: 'pointer'}}
                                    >
                                        <FiInfo />
                                        {visiblePopup === 'variacao_faturavel' && (
                                            <div className="hover-popup" style={popupStyle}> 
                                                Diferença entre total de horas planejadas e o total de horas faturáveis.
                                            </div>
                                        )}
                                    </span>
                                    <SortIcon column="variacaoFaturavel" sortDirection={sortDirection} />
                        </th>
                                </tr>
                            </thead>
                            <tbody>
                                {proj.colaboradores
                                .map((item, index) => {
                                    return (
                                    <tr key={index}>
                                    <td className="fixed-column no-wrap" style={item.colaborador.demissao ? riscoDemissao : {}}>{item.colaborador.nome} {item.colaborador.sobrenome} </td>
                                    <td className="no-wrap">{item.colaborador.departamento === 5 ? "Delivery" : item.colaborador.departamento === 16 ? "ENGESOL" : "Overhead"}</td>
                                    <td className="no-wrap">{item.colaborador.regime === 1 ? "CLT" : item.colaborador.regime === 3 && "Estagiário"}</td>
                                    <td className="no-wrap">{funcoes.find(funcao => funcao.cod === item.colaborador.funcao)?.nome}</td>
                                    <td className="no-wrap">{item.horasPorDia.toFixed()}h</td>
                                    <td className="no-wrap">{item.totalHorasObrigatorias.toFixed()}h</td>
                                    <td className="no-wrap verde-claro-planejado">{item.totalPlannedHoursInScope.toFixed()}h</td>
                                    <td className={`no-wrap verde-claro-planejado ${item.horasLivres.toFixed() < 0 && "text-danger"}`}>{ Math.floor(item.horasLivres)}h</td>
                                    <td className="no-wrap verde-claro-planejado">{item.colaborador.demissao ? "-- " : ((item.totalPlannedHoursInScope * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                                    <td className="no-wrap azul-claro-realizado">{item.duracao.toFixed()}h</td>
                                    <td className="no-wrap azul-claro-realizado">{((item.duracao * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                                    <td className="no-wrap azul-claro-realizado">{item.horasFaturaveis.toFixed()}h</td>
                                    <td className="no-wrap azul-claro-realizado">{((item.horasFaturaveis * 100) / item.totalHorasObrigatorias).toFixed()}%</td>
                                    <td className="no-wrap cinza-claro-variacao">
                                        {(() => {
                                            const desvio = item.totalPlannedHoursInScope === 0
                                            ? -100
                                            : ((item.totalPlannedHoursInScope - item.horasFaturaveis) / item.totalPlannedHoursInScope) * 100;
        
                                            return `${desvio.toFixed() == -0 ? 0 : desvio.toFixed()}%`;
                                        })()}
                                    </td>
                                    </tr>
                                    )})}
                                <tr>
                                <td className="fixed-column no-wrap"><strong>Total de colaboradores: {proj.colaboradores.length}</strong></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="no-wrap"><strong>{totalHorasObrigatoriasProjetos(proj).toFixed()}h</strong></td>
                                <td className="no-wrap verde-claro-planejado"><strong>{totalPlannedHoursInScopeProjetos(proj).toFixed()}h</strong></td>
                                <td className="no-wrap verde-claro-planejado"><strong>{Math.floor(totalHorasLivresProjetos(proj))}h</strong></td>
                                <td className="no-wrap verde-claro-planejado"><strong>{isNaN(((totalPlannedHoursInScopeProjetos(proj) * 100) / totalHorasObrigatoriasProjetos(proj)).toFixed()) ? "0" : ((totalPlannedHoursInScopeProjetos(proj) * 100) / totalHorasObrigatoriasProjetos(proj)).toFixed()}%</strong></td>
                                <td className="no-wrap azul-claro-realizado"><strong>{totalDuracaoProjetos(proj).toFixed()}h</strong></td>
                                <td className="no-wrap azul-claro-realizado"><strong>{isNaN(((totalDuracaoProjetos(proj) * 100) / totalHorasObrigatoriasProjetos(proj)).toFixed()) ? "0" : ((totalDuracaoProjetos(proj) * 100) / totalHorasObrigatoriasProjetos(proj)).toFixed()}%</strong></td>
                                <td className="no-wrap azul-claro-realizado"><strong>{totalHorasFaturaveisProjetos(proj).toFixed()}h</strong></td>
                                <td className="no-wrap azul-claro-realizado"><strong>{isNaN(((totalHorasFaturaveisProjetos(proj) * 100) / totalHorasObrigatoriasProjetos(proj)).toFixed()) ? "0" : ((totalHorasFaturaveisProjetos(proj) * 100) / totalHorasObrigatoriasProjetos(proj)).toFixed()}%</strong></td>
                                <td className="no-wrap cinza-claro-variacao">
                                    <strong>
                                        {(() => {
                                            const desvio = totalPlannedHoursInScopeProjetos(proj) == 0
                                            ? -100
                                            : ((totalPlannedHoursInScopeProjetos(proj) - totalHorasFaturaveisProjetos(proj)) / totalPlannedHoursInScopeProjetos(proj)) * 100;
        
                                            return `${desvio.toFixed() == -0 ? 0 : desvio.toFixed()}%`;
                                        })()}
                                    </strong>
                                </td>
                                </tr>
                            </tbody>
                            </Table>
                        </div>
                    })}
                </div>
                ) : <div>Nenhum colaborador encontrado.</div>)
                }
        </>
    );
}

export default RelatorioCapacidadeProjetoView;
