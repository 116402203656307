/**
 * Informações das colunas para listagem de faixas de imposto
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   style: string
 * }>}
 */
export const columns = [
    {
        label: 'Colaborador',
        name: 'user',
        width: '18%'
    },
    // {
    //     label: 'Período',
    //     name: 'mes',
    //     width: '20%'
    // },
    {
        label: 'Horas Trabalhadas',
        name: 'timeLogged',
        width: '18%'
    },
    {
        label: 'Custo Médio Hora',
        name: 'custoHH',
        width: '18%'
    },
    {
        label: 'Custo Total',
        name: 'custoTotal',
        width: '15%'
    },
    {
        label: 'Projeto',
        name: 'projeto',
        width: '18%'
    },
    // {
    //     // SEMPRE VISÌVEL
    //     label: 'Adicionar ao custo total',
    //     name: 'addCustoTotal',
    //     width: '20%'
    // },
];


const IssuesListInfo = { columns };

export default IssuesListInfo;
